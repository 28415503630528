<template>
    <div>
      <h1>Escáner de Códigos de Barras</h1>
      
      <button @click="startScanning">Iniciar Escaneo</button>
      <button @click="stopScanning">Detener Escaneo</button>
      <p v-if="scannedCode">Código escaneado: {{ scannedCode }}</p>

      <div id="interactive" class="viewport"></div>
    </div>
  </template>
  
  <script>
  import Quagga from 'quagga';
  
  export default {
    data() {
      return {
        scannedCode: null,
      };
    },
    mounted() {
      this.initQuagga();
    },
    methods: {
      initQuagga() {
        Quagga.init({
          inputStream: {
            name: "Live",
            type: "LiveStream",
            target: document.querySelector('#interactive'), // Selector del elemento donde se mostrará la cámara
            constraints: {
              facingMode: "environment" // Usar la cámara trasera
            },
          },
          decoder: {
            readers: [
              "code_128_reader",
              "ean_reader",
              "ean_8_reader",
              "upc_reader",
              "upc_e_reader",
              "code_39_reader",
              "code_39_vin_reader",
              "i2of5_reader",
              "2of5_reader",
              "code_93_reader",
              "codabar_reader",
            ],
          },
        }, (err) => {
          if (err) {
            console.error(err);
            return;
          }
          Quagga.start();
          this.attachListeners();
        });
      },
      attachListeners() {
        Quagga.onDetected((data) => {
          console.log("Código escaneado:", data);
          this.scannedCode = data.codeResult.code; // Guarda el código escaneado
          Quagga.stop(); // Detiene el escaneo si deseas hacerlo una vez que se ha escaneado un código
        });
      },
      startScanning() {
        Quagga.start();
      },
      stopScanning() {
        Quagga.stop();
      }
    },
    beforeDestroy() {
      Quagga.stop();
      Quagga.offDetected();
    },
  };
  </script>
  
  <style scoped>
  .viewport {
    width: 200px;
    height: 200px;
    /* position: relative; */
  }
  </style>
  