<template>
	<div style="height: inherit">
		<b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">

			<!-- ECommerce Header -->
			<section id="ecommerce-header">
				<div class="row">
					<div class="col-sm-12 mt-1_">
						<div class="ecommerce-header-items">
							<div class="result-toggler">
								<feather-icon icon="MenuIcon" class="d-block d-lg-none" size="21"
									@click="mqShallShowLeftSidebar = true" />
								<div class="search-results d-block">
									<span v-if="totalProducts > 0">
										{{ resolvePaginationTranslate(dataMeta) }}
									</span>
									<span v-else>{{ totalProducts }} resultados encontrados</span>
									
								</div>
							</div>
							<div class="view-options d-flex">

								<!-- Sort Button -->
								<!-- <b-dropdown v-ripple.400="'rgba(113, 102, 240, 0.15)'" :text="sortBy.text" right
									variant="outline-primary">
									<b-dropdown-item v-for="sortOption in sortByOptions" :key="sortOption.value"
										@click="sortBy = sortOption">
										{{ sortOption.text }}
									</b-dropdown-item>
								</b-dropdown> -->

								<!-- Item View Radio Button Group  -->
								<!-- <b-form-radio-group v-model="itemView" class="ml-1 list item-view-radio-group" buttons
									size="sm" button-variant="outline-primary">
									<b-form-radio v-for="option in itemViewOptions" :key="option.value"
										:value="option.value">
										<feather-icon :icon="option.icon" size="18" />
									</b-form-radio>
								</b-form-radio-group> -->

								<b-pagination v-if="totalProducts > pageSize" v-model="pageNumber" :total-rows="totalProducts" :per-page="pageSize"
									first-number align="center" last-number prev-class="prev-item" next-class="next-item">
									<template #prev-text>
										<feather-icon icon="ChevronLeftIcon" />
									</template>
									<template #next-text>
										<feather-icon icon="ChevronRightIcon" />
									</template>
								</b-pagination>
							</div>
						</div>
					</div>
				</div>
			</section>

			<!-- Overlay -->
			<div class="body-content-overlay" />

			<!-- Searchbar -->
			<div class="ecommerce-searchbar mt-1">
				<b-row>
					<b-col cols="12">
						<b-input-group class="input-group-merge">
							<b-form-input ref="inputSearch" autofocus autocomplete="off" v-model="filters.q" @keydown.enter="fetchShopProducts(1)" placeholder="Escanea el código de barras" class="search-product" />
							<b-input-group-append is-text>
								<feather-icon @click="fetchShopProducts(1)" icon="SearchIcon" class="text-muted"/>
							</b-input-group-append>

							<b-input-group-append v-if="isAdministrator">
							<b-button @click="isShowCamera = !isShowCamera" variant="outline-primary">
								<feather-icon icon="CameraIcon" size="18" />
							</b-button>
							</b-input-group-append>
						</b-input-group>
					</b-col>
				</b-row>
			</div>

			<!-- Products -->
			<section :class="itemView">
				<b-card v-for="product in products" :key="product.id" class="ecommerce-card" no-body>
					<div class="d-flex align-items-center justify-content-center mb-2 mb-md-0">
						<div class="d-flex align-items-center justify-content-center mt-1">
							<b-link>
								<b-img v-if="product.image_Exists" :src="convertirABase64(product.imagen)" :alt="`Image of ${product.name}`" class="product-img_" height="150" width="150" />
								<b-img v-else :src="logo_1" :alt="`Image of ${product.name}`" class="product-img_" width="150" height="150" />
							</b-link>
						</div>
					</div>

					<!-- Product Details -->
					<b-card-body>
						<h6 class="item-name">
							<b-link v-if="canDetail" @click="setDetailProduct(product)" class="text-body">{{ product.name }}</b-link>
							<b-link v-else class="text-body">{{ product.name }}</b-link>
						</h6>

						<b-card-text class="item-description">
							<strong>Clave Principal: </strong> {{ product.clabe }}<br>
							<strong>Código Barras: </strong> {{ product.code_bar }}<br>
							<strong>Unidad Venta: </strong> {{ product.unit }}
						</b-card-text>

						<b-card-text class="item-description">{{ product.isAvailable ? '' : 'No' }} Disponible - 
							<span :class="`text-${product.isAvailable ? 'success' : 'danger'}`">En stock</span>
							<b-list-group v-if="product.isAvailable">
								<b-list-group-item v-for="(existencia, idxE) in product.existencias" :key="idxE"
									class="d-flex justify-content-between align-items-center"
									style="padding: 0.25rem!important;">
									<small>{{ existencia.almacen }}</small>
									<b-badge variant="primary" pill class="badge-round">
										{{ existencia.existencia }}
									</b-badge>
								</b-list-group-item>
							</b-list-group>
						</b-card-text>
					</b-card-body>

					<!-- Product Actions -->
					<div class="item-options text-center">
						<div class="item-wrapper">
							<div class="item-cost">
								<h6 class="item-price_">Precio {{ formatCurrency(product.price) }}</h6>
							</div>
						</div>

						<div class="item-wrapper">
							<div class="item-cost">
								<h6 class="item-price_">IVA {{ formatCurrency(product.tax) }}</h6>
							</div>
						</div>

						<div class="item-wrapper">
							<div class="item-cost">
								<h3 class="item-price">Precio Neto {{ formatCurrency(product.price_net) }}</h3>
							</div>
						</div>
						
						<b-button v-if="canDetail" variant="light" tag="a"
							@click="setDetailProduct(product)"
							class="btn-wishlist">
							<feather-icon icon="EyeIcon" class="mr-50"/>
							<span>Detalle</span>
						</b-button>

						<b-button v-if="canRequestArticle" variant="primary" tag="a" class="btn-cart"
							@click="requestProduct(product)">
							<feather-icon icon="ShoppingCartIcon" class="mr-50"/>
							<span>Solicitar</span>
						</b-button>
					</div>
				</b-card>
			</section>
			
			<!-- Pagination -->
			<section v-if="totalProducts > pageSize">
				<b-row>
					<b-col cols="12">
						<b-pagination v-model="pageNumber" :total-rows="totalProducts" :per-page="pageSize"
							first-number align="center" last-number prev-class="prev-item" next-class="next-item">
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</section>

			<!-- Sidebar -->
			<portal to="content-renderer-sidebar-detached-left">
				<shop-left-filter-sidebar :filters="filters" :filter-options="filterOptions"
					:mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar" />
			</portal>
		</b-overlay>

		<!-- Modal Scan QR -->
		<b-modal
			ref="infoModalReaderQR"
			id="modalReaderQR"
			title="Escanee el código del producto"
			no-close-on-backdrop
			hide-footer
			@cancel="isShowCamera = false"
			@hidden="closeModalCamera"
			:visible="isShowCamera"
			:size="isMobile ? 'md' : 'xlg'"
		>
			<!-- <vue-qr-reader
				v-if="isShowCamera"
				v-on:code-scanned="codeScanned"
				v-on:error-captured="errorCaptured"
				:stop-on-scanned="true"
				:draw-on-found="true"
				:responsive="true"
				:video-height="videoHeight"
				:video-width="videoWidth"
			/> -->
				<!-- <zxing-scan v-if="isShowCamera" @onScanComplete="handleScanComplete"/> -->
				<StreamBarcodeReader
					ref="barcodeReader"
					@decode="onDecode" 
					@loaded="onLoaded" 
					:cameraFacingMode="'environment'"
					@error="onCameraError"					
				/>
			<hr>
			<div class="text-right mt-2">
				<b-button
					type="button"
					v-ripple.400="'rgba(255, 255, 255, 0.15)'"
					variant="outline-secondary"
					class="mr-2"
					@click="isShowCamera = !isShowCamera">
					Cancelar
				</b-button>
			</div>
		</b-modal>

	</div>
</template>

<script>
import {
	BDropdown, BDropdownItem, BFormRadioGroup, BFormRadio, BRow, BCol, BInputGroup, BInputGroupAppend, 
	BFormInput, BCard, BCardBody, BLink, BImg, BCardText, BButton, BPagination, BListGroup, BListGroupItem, BBadge,
	BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { watch, onMounted, computed, nextTick, ref,} from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ShopLeftFilterSidebar from './ECommerceShopLeftFilterSidebar.vue'
import { useShopFiltersSortingAndPagination, useShopUi, useShopRemoteData } from './useECommerceShop'
import { useEcommerceUi } from '../useEcommerce'
import useAppConfig from '@core/app-config/useAppConfig'
import store from '@/store'
import moment from 'moment'
import router from '@/router'
import * as helper from '@/libs/helpers';
import { useWindowScroll } from '@vueuse/core'
import VueQrReader from '@/@core/components/app-scan/VueQrReader.vue'
import ZxingScan from '@/@core/components/app-scan/ZxingScan.vue'
import { StreamBarcodeReader, ImageBarcodeReader  } from "vue-barcode-reader";
import QuaggaReader from '@/@core/components/app-scan/QuaggaReader.vue'
import ArticlesProvider from '@/providers/Articles'
const ArticlesResource = new ArticlesProvider()

import PricesArticlesProvider from '@/providers/PricesArticles';

const PricesArticlesResource = new PricesArticlesProvider();

export default {
	directives: {
		Ripple,
	},
	components: {
		// BSV
		BDropdown,
		BDropdownItem,
		BFormRadioGroup,
		BFormRadio,
		BRow,
		BCol,
		BInputGroup,
		BInputGroupAppend,
		BFormInput,
		BCard,
		BCardBody,
		BLink,
		BImg,
		BCardText,
		BButton,
		BPagination,
		BListGroup, 
		BListGroupItem, 
		BBadge,
		BOverlay,

		// SFC
		ShopLeftFilterSidebar,
		VueQrReader,
		ZxingScan,
		StreamBarcodeReader,
		ImageBarcodeReader,
		QuaggaReader,
	},
	data() {
		return {
			logo: require('@/assets/images/logo/ferreysa.png'),
            logo_1: require('@/assets/images/logo/ferreysa_1.jpg'),
            logo_2: require('@/assets/images/logo/ferreysa_2.jpg'),
            logo_3: require('@/assets/images/logo/ferreysa_3.jpg'),
            isMobile: false,
			errorMessage: '',
			videoWidth: 740,
        	videoHeight: 440,
			scrollPosition: 0,
			scannedCode: '',
			facingMode: 'environment', // Cámara trasera
			mediaStream: null, // Para almacenar el stream de la cámara
			cameraError: null, // Almacenamos cualquier error relacionado con la cámara
		}
	},
	computed: {
		canDetail () {
			return this.canAccess(42) // Permitir ver el detalle del artÃ­culo
		},
		canRequestArticle () {
			return this.canAccess(45) // Permitir solicitar el articulo
		},
		isAdministrator() {
			return store.getters['auth/getUser'] ? (store.getters['auth/getUser'].customRol.id == store.getters['auth/getAdministratorRol'] || store.getters['auth/getRolDirector'] ) : false
		},
	},
	beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
	beforeRouteLeave(to, from, next) {
		// Guardar la posición de scroll antes de ir a la vista de detalles
		this.scrollPosition = window.scrollY;
		next();
	},
	mounted() {

		window.addEventListener('resize', this.handleResize);
        this.handleResize()

		const detailProduct = store.getters['app-ecommerce/getProductDetail']// JSON.parse(localStorage.getItem('detailProduct'))
		if (detailProduct) {
			this.$nextTick( async () => {
				this.filters = detailProduct.filter
				this.pageNumber = detailProduct.pageNumber
				this.pageSize = detailProduct.pageSize				
				this.totalProducts = detailProduct.totalProducts
				//this.products = detailProduct.products
				await this.fetchShopProducts(this.pageNumber)

				// Asegurar que el contenido esté completamente cargado
				setTimeout(() => {
					const scrollY = localStorage.getItem('scrollY');
					if (scrollY) {
						window.scrollTo(0, parseInt(scrollY, 0));
					}
				}, 300);  // Un delay de 300ms para asegurar que todo esté cargado
			})
		}
	},
	methods: {
		async handleScan (result) {
			this.filters.q = result.code
			this.isShowCamera = false
			this.stopScanning(); // Detiene la cámara

			// Realizar la búsqueda
			await this.fetchShopProducts(1);

		},
		stopScanning () {
			if (this.mediaStream) {
				// Detener cada track de la cámara
				this.mediaStream.getTracks().forEach(track => track.stop());
			}
		},
		async onDecode (text) { 
			this.filters.q = text
			this.isShowCamera = false
			this.stopScanning()

			// Realizar la búsqueda
			await this.fetchShopProducts(1);
		},
		onLoaded() {
			const videoElement = this.$refs.barcodeReader.$el.querySelector('video');
			console.log(videoElement)
			if (videoElement && videoElement.srcObject) {
				this.mediaStream = videoElement.srcObject;
			}
		},
		onCameraError(error) {
			console.error("Error al acceder a la cámara: ", error);
			
			// Mostramos un mensaje adecuado basado en el error capturado
			if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
				this.cameraError = "Permiso para acceder a la cámara denegado. Por favor, habilite el acceso a la cámara.";
			} else if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
				this.cameraError = "No se encontró ninguna cámara en el dispositivo.";
			} else {
				this.cameraError = "Ocurrió un error al intentar acceder a la cámara.";
			}

			this.danger(this.cameraError);
			
			this.isShowCamera = false;
		},
		onError(r) {
			console.log(r)
		},
		handleResize() {
            this.isMobile = window.innerWidth < 998;
        },
		handleScanComplete(result) {
			console.log(result)
			this.filters.q = result
			this.isShowCamera = false
		},
		errorCaptured(error) {
			const errorMessages = {
				"NotAllowedError": "Camera permission denied.",
				"NotFoundError": "There is no connected camera.",
				"NotSupportedError": "Seems like this page is served in non-secure context.",
				"NotReadableError": "Couldn't access your camera. Is it already in use?",
				"OverconstrainedError": "Constraints don't match any installed camera.",
			};
			this.errorMessage = errorMessages[error.name] || "UNKNOWN ERROR: " + error.message;
			this.danger(this.errorMessage)
        },
		async codeScanned(code) {
          const search = code;
		  console.log(search)
          if (search) {
			this.filters.q = search
            this.isShowCamera = false
            await this.fetchShopProducts(1)
          }
        },
		closeModalCamera() {
          this.isShowCamera = false
          this.$refs.infoModalReaderQR.hide()
        },
		setDetailProduct(product) {
			
			store.commit('app-ecommerce/SET_DETAIL_PRODUCT', null)
			const detail = {
				product: product,
				filter: this.filters,
				pageNumber: this.pageNumber,
				pageSize: this.pageSize,
				// products: this.products,
				totalProducts: this.totalProducts,				
			}	

			localStorage.setItem('scrollY', window.scrollY)
			//localStorage.setItem('detailProduct', JSON.stringify(detail))
			localStorage.setItem('searchProductText', this.filters.q)
			store.commit('app-ecommerce/SET_DETAIL_PRODUCT', {...detail})
			
			this.$router.push({ name: 'apps-e-commerce-product-details', params: { slug: product.slug } })
		},
		async requestProduct(product) {
			const payload = {
				id: 0,
				empresa_Id: 0,
				usuario_Id: "string",
				articulo_Id: product.id,
				articulo: product.name,
				clave_Articulo: product.clabe,
				estatus: "ACTIVO",
				fecha: "2024-10-15T17:43:17.892Z",
				fecha_Alta: "2024-10-15T17:43:17.892Z",
				fecha_Modificacion: "2024-10-15T17:43:17.892Z"
			}
			
			try {
				this.loading = true
				const { data } = await ArticlesResource.requestArticles(payload)
				this.loading = false
				if (data.isSuccesful) {
					this.success('Producto solicitado correctamente')
				} else {
					this.danger(data.message)
				}
			}catch (e) {
				this.loading = false
				this.handleResponseErrors(e)
			}
		},
		checkCameraPermissions() {
			// Intentamos acceder a la cámara para verificar si los permisos están habilitados
			navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
				// Permisos concedidos, no hacemos nada
				stream.getTracks().forEach(track => track.stop()); // Detenemos el stream si solo queremos verificar permisos
				})
				.catch((error) => {
					// Manejo de errores al denegar los permisos
					if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
						this.cameraError = "Permiso para acceder a la cámara denegado. Por favor, habilite el acceso a la cámara.";
					} else if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
						this.cameraError = "No se encontró ninguna cámara en el dispositivo.";
					} else {
						this.cameraError = "Ocurrió un error al intentar acceder a la cámara.";
					}
					this.danger(this.cameraError);
					this.isShowCamera = false;
				
				});
		}
		
	},
	watch:{
		isShowCamera(value) {
			if(!value){
				this.stopScanning()
			} else {
				this.cameraError = null; // Limpiar cualquier error previo cuando se vuelve a abrir el modal
				
				// Verificar permisos manualmente al abrir el modal				
				this.checkCameraPermissions();
			}
		}
	},
	setup() {

		const {
			skin,
			navbarType,
			footerType,
			routerTransition,
			isNavMenuHidden,
		} = useAppConfig()

		const isShowCamera = ref(false)

		const { y } = useWindowScroll()

		const loading = ref(false)
		const pageNumber = ref(1)
        const pageSize = ref(10)
		
		// Define la referencia
        const inputSearch = ref(null);
        
		const scrollToTop = () => {
			const rootEle = document.documentElement
			rootEle.scrollTo({
				top: 0,
				behavior: 'smooth',
			})
		}

		const {
			filters, filterOptions, sortBy, sortByOptions,
		} = useShopFiltersSortingAndPagination()

		const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

		const { itemView, itemViewOptions, totalProducts,} = useShopUi()

		const { products, fetchProducts } = useShopRemoteData()

		const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

		const isAuxiliar = computed(() => {
			return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == store.getters['auth/getAuxiliarRol'] : false
		})

		// Función para enfocar el input
		const focusInput = () => {
            if (inputSearch.value) {
                inputSearch.value.focus(); // Usa .value para acceder al elemento
            }
        };

		const dataMeta = computed(() => {
			const localItemsCount = _.size(products.value);
            return {
                from:
                    pageSize.value * (pageNumber.value - 1) +
                    (localItemsCount ? 1 : 0),
                to: pageSize.value * (pageNumber.value - 1) + localItemsCount,
                of: totalProducts.value,
            };
		}) 
           
        

		// Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
		const fetchShopProducts = async (page = 1) => {

			//localStorage.setItem('detailProduct', null)
			store.commit('app-ecommerce/SET_DETAIL_PRODUCT', null)
			const text = localStorage.getItem('searchProductText')
			if (text != filters.value.q) {
				page = 1
				totalProducts.value = 0
				pageNumber.value = 1	
			}

			const query = {
                search: filters.value.q,
                pageNumber: page,
                pageSize: pageSize.value,
                precioLista: filters.value.priceRangeDefined,
            }

			if (!query.search && query.search != '') {
				helper.danger('Ingrese un código de barras, clave principal o nombre de producto')
                return
			}

			try {
                loading.value = true
                const { data } = await ArticlesResource.searchArticlesByQr(query)
                loading.value = false
                if (data.isSuccesful && data.data.length > 0) {
					localStorage.setItem('searchProductText', query.search)
                    products.value = _.orderBy(data.data.map(article => {
                        return {
                            id: article.articulo_Id,
                            name: article.articulo,
                            slug: article.articulo_Id,
                            price: article.precio,
                            tax: article.iva,
                            price_net: article.precio_Neto,
                            description: article.articulo,
                            brand: '',
                            rating: 5,
                            imagen: article.imagen,
                            unit: article.unidad_Venta,
                            isInWishlist: false,
                            isInCart: false,
                            isAvailable: article.existencias.reduce((acc, existencia) => acc + existencia.existencia, 0) > 0,
                            clabe: article.clave_Articulo,
                            code_bar: article.codigo_Barras,
                            existencias: article.existencias,
                            precios: article.preciosEmpresa,
                            image_Exists: article.imagen_Exists,
							clabes: article.claves,
							niveles: article.niveles,
							storable: article.es_Almacenable === 'S',
                        }
                    }), ['clabe'], ['asc'])

                    totalProducts.value = data.totalRecords
                } else {
					localStorage.setItem('searchProductText', '')
                    helper.danger('No se encontraron resultados')
                }
            } catch (e) {
                console.log(e)
				localStorage.setItem('searchProductText', '')
				// localStorage.setItem('detailProduct', null)
				store.commit('app-ecommerce/SET_DETAIL_PRODUCT', null)
				products.value = []
				totalProducts.value = 0				
                helper.handleResponseErrors(e)
            } finally {
                loading.value = false
            }

			focusInput()
			// filters.value.q = ''
		}
		

		onMounted(async () => {
			
			//localStorage.setItem('searchProductText', '')

			if (isAuxiliar.value) {
				store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
				store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: 'hidden' })
			}			
		})		

		// Verifica cuando el número de página cambie y actualiza los artículos
		watch(pageNumber, async (newPage) => {
            await fetchShopProducts(newPage)
			// scrollToTop()
        })

		// Verifica cuando los filtros cambien y actualiza los artículos
		watch([filters], (filter) => {
			const { priceRangeDefined } = filters.value
			if (priceRangeDefined) {
				products.value = products.value.map(product => {
                    const price = product.precios.find(precio => precio.precio_Empresa_Id === priceRangeDefined)
                    if (price) {
                        product.price = price.precio
                        product.tax = price.precio_Con_Impto - price.precio
                        product.price_net = price.precio_Con_Impto
                    } else {
                        product.price = 0
                        product.tax = 0
                        product.price_net = 0
                    }
                    return product
                })
			}

		}, {
			deep: true,
		})

		return {
			// useShopFiltersSortingAndPagination
			filters,
			filterOptions,
			sortBy,
			sortByOptions,
			pageNumber,
			pageSize,
			loading,
			inputSearch,
			isShowCamera,

			// useShopUi
			itemView,
			itemViewOptions,
			totalProducts,
			dataMeta,
			toggleProductInWishlist,
			handleCartActionClick,

			// useShopRemoteData
			products,

			// mqShallShowLeftSidebar
			mqShallShowLeftSidebar,

			fetchShopProducts,
		}
	},
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
	.btn {
		display: flex;
		align-items: center;
	}
}

.viewport {
  width: 100%;
  height: 300px;
  position: relative;
}
</style>
